import { defineNuxtPlugin } from '#app/nuxt'
import { LazySvgo5min, LazySvgoCasting, LazySvgoFamous, LazySvgoFilmReview, LazySvgoFilmchallenge, LazySvgoFivefest, LazySvgoMovieScreen, LazySvgoProduction, LazySvgoScenario, LazySvgoStorytelling } from '#components'
const lazyGlobalComponents = [
  ["Svgo5min", LazySvgo5min],
["SvgoCasting", LazySvgoCasting],
["SvgoFamous", LazySvgoFamous],
["SvgoFilmReview", LazySvgoFilmReview],
["SvgoFilmchallenge", LazySvgoFilmchallenge],
["SvgoFivefest", LazySvgoFivefest],
["SvgoMovieScreen", LazySvgoMovieScreen],
["SvgoProduction", LazySvgoProduction],
["SvgoScenario", LazySvgoScenario],
["SvgoStorytelling", LazySvgoStorytelling],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
