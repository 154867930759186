import { OpenFeature, ProviderEvents } from '@openfeature/web-sdk';
import { GoFeatureFlagWebProvider } from '@openfeature/go-feature-flag-web-provider';
import type { MiddlewareKey } from '#build/types/middleware';

export default defineNuxtPlugin(async () => {
  const config = useRuntimeConfig()

  const goFeatureFlagWebProvider = new GoFeatureFlagWebProvider({
    endpoint: config.public.goff.endpoint
  });

  OpenFeature.setProvider(goFeatureFlagWebProvider);

  const client = OpenFeature.getClient("festival-manager", config.public.datadog.version || 'dev');
  await new Promise((resolve) => {
    client.addHandler(ProviderEvents.Ready, () => {
      resolve(true);
    });

    client.addHandler(ProviderEvents.ConfigurationChanged, (event) => {
      const route = useRoute()
      const middleware = route.meta.middleware as MiddlewareKey[]

      if (!middleware.includes('open-feature')) return

      const features = route.meta.openFeatures as string[]
      for (const feature of features) {
        if (event?.flagsChanged?.includes(feature)) {
          if (client.getBooleanValue(feature, false)) return

          return navigateTo('/', { replace: true })
        }
      }
    })
  });

  return {
    provide: {
      of: client
    }
  }
})
