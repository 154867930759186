import { datadogRum } from '@datadog/browser-rum'
import { OpenFeature, type EvaluationDetails, type FlagValue, type Hook, type HookContext } from '@openfeature/web-sdk'

export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig()

  datadogRum.init({
    applicationId: config.public.datadog.applicationId,
    clientToken: config.public.datadog.clientToken,
    site: 'datadoghq.com',
    service: 'sato48',
    env: config.public.datadog.env,
    version: config.public.datadog.version || 'dev',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackResources: true,
    trackUserInteractions: true,
    defaultPrivacyLevel: 'allow',
    enableExperimentalFeatures: ['clickmap', 'feature_flags'],
  })

  datadogRum.startSessionReplayRecording()

  OpenFeature.addHooks(new DatadogRUMHook())
})

class DatadogRUMHook implements Hook {
  after(hookContext: Readonly<HookContext<FlagValue>>, evaluationDetails: EvaluationDetails<FlagValue>, hookHints?: Readonly<Record<string, unknown>> | undefined): void {
    datadogRum.addFeatureFlagEvaluation(
      evaluationDetails.flagKey,
      evaluationDetails.value
    )
  }
}
