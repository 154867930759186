import { default as _91filmGroupId_93sBkaAXSS26Meta } from "/private/tmp/nix-build-festival-manager-0.0.1.drv-0/incl/pages/admin/audience-favorite/[filmGroupId].vue?macro=true";
import { default as indexgdwLpv94g2Meta } from "/private/tmp/nix-build-festival-manager-0.0.1.drv-0/incl/pages/admin/audience-favorite/index.vue?macro=true";
import { default as checkinKUwEEBCQwEMeta } from "/private/tmp/nix-build-festival-manager-0.0.1.drv-0/incl/pages/admin/awards-ceremony/checkin.vue?macro=true";
import { default as exit_45interviewsSCEEcDYm1DMeta } from "/private/tmp/nix-build-festival-manager-0.0.1.drv-0/incl/pages/admin/exit-interviews.vue?macro=true";
import { default as filmsIzyosr3ylEMeta } from "/private/tmp/nix-build-festival-manager-0.0.1.drv-0/incl/pages/admin/film-groups/films.vue?macro=true";
import { default as userst8z5gajROnMeta } from "/private/tmp/nix-build-festival-manager-0.0.1.drv-0/incl/pages/admin/film-groups/users.vue?macro=true";
import { default as academycGMmdXIaP9Meta } from "/private/tmp/nix-build-festival-manager-0.0.1.drv-0/incl/pages/admin/results/academy.vue?macro=true";
import { default as judgeKMwyhf4wj4Meta } from "/private/tmp/nix-build-festival-manager-0.0.1.drv-0/incl/pages/admin/results/judge.vue?macro=true";
import { default as selectionypcsybgb8BMeta } from "/private/tmp/nix-build-festival-manager-0.0.1.drv-0/incl/pages/admin/results/selection.vue?macro=true";
import { default as sharingQvpGeY4rc2Meta } from "/private/tmp/nix-build-festival-manager-0.0.1.drv-0/incl/pages/admin/sharing.vue?macro=true";
import { default as teamss497QCTXuOMeta } from "/private/tmp/nix-build-festival-manager-0.0.1.drv-0/incl/pages/admin/teams.vue?macro=true";
import { default as userskCz6mYPmFiMeta } from "/private/tmp/nix-build-festival-manager-0.0.1.drv-0/incl/pages/admin/users.vue?macro=true";
import { default as _91teamId_937C1W0muPAmMeta } from "/private/tmp/nix-build-festival-manager-0.0.1.drv-0/incl/pages/archive/[year]/[teamId].vue?macro=true";
import { default as top_4510lXPQDrGrwjMeta } from "/private/tmp/nix-build-festival-manager-0.0.1.drv-0/incl/pages/archive/[year]/top-10.vue?macro=true";
import { default as indexZsD42rxKgfMeta } from "/private/tmp/nix-build-festival-manager-0.0.1.drv-0/incl/pages/archive/index.vue?macro=true";
import { default as box_45officexldyuuWlZKMeta } from "/private/tmp/nix-build-festival-manager-0.0.1.drv-0/incl/pages/box-office.vue?macro=true";
import { default as _91hash_93ewu1r6xQdpMeta } from "/private/tmp/nix-build-festival-manager-0.0.1.drv-0/incl/pages/film-challenge/guest-registration/[hash].vue?macro=true";
import { default as indexscSpdXm0R2Meta } from "/private/tmp/nix-build-festival-manager-0.0.1.drv-0/incl/pages/film-challenge/index.vue?macro=true";
import { default as inspirationCXgTr5FbUpMeta } from "/private/tmp/nix-build-festival-manager-0.0.1.drv-0/incl/pages/film-challenge/inspiration.vue?macro=true";
import { default as indexmg8Ex1PPSMMeta } from "/private/tmp/nix-build-festival-manager-0.0.1.drv-0/incl/pages/film-challenge/studios/[studioId]/index.vue?macro=true";
import { default as _91teamId_936C3pGwS4r0Meta } from "/private/tmp/nix-build-festival-manager-0.0.1.drv-0/incl/pages/film-challenge/studios/[studioId]/teams/[teamId].vue?macro=true";
import { default as createTOlBw4lrMeMeta } from "/private/tmp/nix-build-festival-manager-0.0.1.drv-0/incl/pages/film-challenge/studios/[studioId]/teams/create.vue?macro=true";
import { default as the_45matchupSBiUZYjQoYMeta } from "/private/tmp/nix-build-festival-manager-0.0.1.drv-0/incl/pages/film-challenge/the-matchup.vue?macro=true";
import { default as _91filmGroupId_93qoZRoTBQM8Meta } from "/private/tmp/nix-build-festival-manager-0.0.1.drv-0/incl/pages/fivefest/academy/film-groups/[filmGroupId].vue?macro=true";
import { default as indexUs8ulZPLMGMeta } from "/private/tmp/nix-build-festival-manager-0.0.1.drv-0/incl/pages/fivefest/academy/index.vue?macro=true";
import { default as _91submissionId_93U0WrNZ2YkmMeta } from "/private/tmp/nix-build-festival-manager-0.0.1.drv-0/incl/pages/fivefest/films/[submissionId].vue?macro=true";
import { default as createD4MzSQo8snMeta } from "/private/tmp/nix-build-festival-manager-0.0.1.drv-0/incl/pages/fivefest/films/create.vue?macro=true";
import { default as indexfmiVWxUqwhMeta } from "/private/tmp/nix-build-festival-manager-0.0.1.drv-0/incl/pages/index.vue?macro=true";
import { default as _91filmGroupId_93YF2IYnzQJmMeta } from "/private/tmp/nix-build-festival-manager-0.0.1.drv-0/incl/pages/judge/[filmGroupId].vue?macro=true";
import { default as index870o2QIV0JMeta } from "/private/tmp/nix-build-festival-manager-0.0.1.drv-0/incl/pages/judge/index.vue?macro=true";
import { default as liveB16r7dHWtxMeta } from "/private/tmp/nix-build-festival-manager-0.0.1.drv-0/incl/pages/live.vue?macro=true";
import { default as _91filmGroupId_93RAioPA7QjIMeta } from "/private/tmp/nix-build-festival-manager-0.0.1.drv-0/incl/pages/online-screening/[filmGroupId].vue?macro=true";
import { default as _91filmGroupId_93W04x8mACsxMeta } from "/private/tmp/nix-build-festival-manager-0.0.1.drv-0/incl/pages/online-screening/five-fest/[filmGroupId].vue?macro=true";
import { default as indexhVAbNA0GnzMeta } from "/private/tmp/nix-build-festival-manager-0.0.1.drv-0/incl/pages/online-screening/five-fest/index.vue?macro=true";
import { default as indexK3wXll68AlMeta } from "/private/tmp/nix-build-festival-manager-0.0.1.drv-0/incl/pages/online-screening/index.vue?macro=true";
import { default as oryY0vczVukYeMeta } from "/private/tmp/nix-build-festival-manager-0.0.1.drv-0/incl/pages/ory.vue?macro=true";
import { default as indexrY4rV2pJv6Meta } from "/private/tmp/nix-build-festival-manager-0.0.1.drv-0/incl/pages/prize-sponsor/index.vue?macro=true";
import { default as save_45the_45datesBRHsfRpdIOMeta } from "/private/tmp/nix-build-festival-manager-0.0.1.drv-0/incl/pages/save-the-dates.vue?macro=true";
import { default as indexP5bHRYT5Q6Meta } from "/private/tmp/nix-build-festival-manager-0.0.1.drv-0/incl/pages/selection/[filmGroupId]/index.vue?macro=true";
import { default as reviewjfDdIamJbyMeta } from "/private/tmp/nix-build-festival-manager-0.0.1.drv-0/incl/pages/selection/[filmGroupId]/review.vue?macro=true";
import { default as indexGrpwBmZr7rMeta } from "/private/tmp/nix-build-festival-manager-0.0.1.drv-0/incl/pages/selection/index.vue?macro=true";
export default [
  {
    name: _91filmGroupId_93sBkaAXSS26Meta?.name ?? "admin-audience-favorite-filmGroupId",
    path: _91filmGroupId_93sBkaAXSS26Meta?.path ?? "/admin/audience-favorite/:filmGroupId()",
    meta: _91filmGroupId_93sBkaAXSS26Meta || {},
    alias: _91filmGroupId_93sBkaAXSS26Meta?.alias || [],
    redirect: _91filmGroupId_93sBkaAXSS26Meta?.redirect || undefined,
    component: () => import("/private/tmp/nix-build-festival-manager-0.0.1.drv-0/incl/pages/admin/audience-favorite/[filmGroupId].vue").then(m => m.default || m)
  },
  {
    name: indexgdwLpv94g2Meta?.name ?? "admin-audience-favorite",
    path: indexgdwLpv94g2Meta?.path ?? "/admin/audience-favorite",
    meta: indexgdwLpv94g2Meta || {},
    alias: indexgdwLpv94g2Meta?.alias || [],
    redirect: indexgdwLpv94g2Meta?.redirect || undefined,
    component: () => import("/private/tmp/nix-build-festival-manager-0.0.1.drv-0/incl/pages/admin/audience-favorite/index.vue").then(m => m.default || m)
  },
  {
    name: checkinKUwEEBCQwEMeta?.name ?? "admin-awards-ceremony-checkin",
    path: checkinKUwEEBCQwEMeta?.path ?? "/admin/awards-ceremony/checkin",
    meta: checkinKUwEEBCQwEMeta || {},
    alias: checkinKUwEEBCQwEMeta?.alias || [],
    redirect: checkinKUwEEBCQwEMeta?.redirect || undefined,
    component: () => import("/private/tmp/nix-build-festival-manager-0.0.1.drv-0/incl/pages/admin/awards-ceremony/checkin.vue").then(m => m.default || m)
  },
  {
    name: exit_45interviewsSCEEcDYm1DMeta?.name ?? "admin-exit-interviews",
    path: exit_45interviewsSCEEcDYm1DMeta?.path ?? "/admin/exit-interviews",
    meta: exit_45interviewsSCEEcDYm1DMeta || {},
    alias: exit_45interviewsSCEEcDYm1DMeta?.alias || [],
    redirect: exit_45interviewsSCEEcDYm1DMeta?.redirect || undefined,
    component: () => import("/private/tmp/nix-build-festival-manager-0.0.1.drv-0/incl/pages/admin/exit-interviews.vue").then(m => m.default || m)
  },
  {
    name: filmsIzyosr3ylEMeta?.name ?? "admin-film-groups-films",
    path: filmsIzyosr3ylEMeta?.path ?? "/admin/film-groups/films",
    meta: filmsIzyosr3ylEMeta || {},
    alias: filmsIzyosr3ylEMeta?.alias || [],
    redirect: filmsIzyosr3ylEMeta?.redirect || undefined,
    component: () => import("/private/tmp/nix-build-festival-manager-0.0.1.drv-0/incl/pages/admin/film-groups/films.vue").then(m => m.default || m)
  },
  {
    name: userst8z5gajROnMeta?.name ?? "admin-film-groups-users",
    path: userst8z5gajROnMeta?.path ?? "/admin/film-groups/users",
    meta: userst8z5gajROnMeta || {},
    alias: userst8z5gajROnMeta?.alias || [],
    redirect: userst8z5gajROnMeta?.redirect || undefined,
    component: () => import("/private/tmp/nix-build-festival-manager-0.0.1.drv-0/incl/pages/admin/film-groups/users.vue").then(m => m.default || m)
  },
  {
    name: academycGMmdXIaP9Meta?.name ?? "admin-results-academy",
    path: academycGMmdXIaP9Meta?.path ?? "/admin/results/academy",
    meta: academycGMmdXIaP9Meta || {},
    alias: academycGMmdXIaP9Meta?.alias || [],
    redirect: academycGMmdXIaP9Meta?.redirect || undefined,
    component: () => import("/private/tmp/nix-build-festival-manager-0.0.1.drv-0/incl/pages/admin/results/academy.vue").then(m => m.default || m)
  },
  {
    name: judgeKMwyhf4wj4Meta?.name ?? "admin-results-judge",
    path: judgeKMwyhf4wj4Meta?.path ?? "/admin/results/judge",
    meta: judgeKMwyhf4wj4Meta || {},
    alias: judgeKMwyhf4wj4Meta?.alias || [],
    redirect: judgeKMwyhf4wj4Meta?.redirect || undefined,
    component: () => import("/private/tmp/nix-build-festival-manager-0.0.1.drv-0/incl/pages/admin/results/judge.vue").then(m => m.default || m)
  },
  {
    name: selectionypcsybgb8BMeta?.name ?? "admin-results-selection",
    path: selectionypcsybgb8BMeta?.path ?? "/admin/results/selection",
    meta: selectionypcsybgb8BMeta || {},
    alias: selectionypcsybgb8BMeta?.alias || [],
    redirect: selectionypcsybgb8BMeta?.redirect || undefined,
    component: () => import("/private/tmp/nix-build-festival-manager-0.0.1.drv-0/incl/pages/admin/results/selection.vue").then(m => m.default || m)
  },
  {
    name: sharingQvpGeY4rc2Meta?.name ?? "admin-sharing",
    path: sharingQvpGeY4rc2Meta?.path ?? "/admin/sharing",
    meta: sharingQvpGeY4rc2Meta || {},
    alias: sharingQvpGeY4rc2Meta?.alias || [],
    redirect: sharingQvpGeY4rc2Meta?.redirect || undefined,
    component: () => import("/private/tmp/nix-build-festival-manager-0.0.1.drv-0/incl/pages/admin/sharing.vue").then(m => m.default || m)
  },
  {
    name: teamss497QCTXuOMeta?.name ?? "admin-teams",
    path: teamss497QCTXuOMeta?.path ?? "/admin/teams",
    meta: teamss497QCTXuOMeta || {},
    alias: teamss497QCTXuOMeta?.alias || [],
    redirect: teamss497QCTXuOMeta?.redirect || undefined,
    component: () => import("/private/tmp/nix-build-festival-manager-0.0.1.drv-0/incl/pages/admin/teams.vue").then(m => m.default || m)
  },
  {
    name: userskCz6mYPmFiMeta?.name ?? "admin-users",
    path: userskCz6mYPmFiMeta?.path ?? "/admin/users",
    meta: userskCz6mYPmFiMeta || {},
    alias: userskCz6mYPmFiMeta?.alias || [],
    redirect: userskCz6mYPmFiMeta?.redirect || undefined,
    component: () => import("/private/tmp/nix-build-festival-manager-0.0.1.drv-0/incl/pages/admin/users.vue").then(m => m.default || m)
  },
  {
    name: _91teamId_937C1W0muPAmMeta?.name ?? "archive-year-teamId",
    path: _91teamId_937C1W0muPAmMeta?.path ?? "/archive/:year()/:teamId()",
    meta: _91teamId_937C1W0muPAmMeta || {},
    alias: _91teamId_937C1W0muPAmMeta?.alias || [],
    redirect: _91teamId_937C1W0muPAmMeta?.redirect || undefined,
    component: () => import("/private/tmp/nix-build-festival-manager-0.0.1.drv-0/incl/pages/archive/[year]/[teamId].vue").then(m => m.default || m)
  },
  {
    name: top_4510lXPQDrGrwjMeta?.name ?? "archive-year-top-10",
    path: top_4510lXPQDrGrwjMeta?.path ?? "/archive/:year()/top-10",
    meta: top_4510lXPQDrGrwjMeta || {},
    alias: top_4510lXPQDrGrwjMeta?.alias || [],
    redirect: top_4510lXPQDrGrwjMeta?.redirect || undefined,
    component: () => import("/private/tmp/nix-build-festival-manager-0.0.1.drv-0/incl/pages/archive/[year]/top-10.vue").then(m => m.default || m)
  },
  {
    name: indexZsD42rxKgfMeta?.name ?? "archive",
    path: indexZsD42rxKgfMeta?.path ?? "/archive",
    meta: indexZsD42rxKgfMeta || {},
    alias: indexZsD42rxKgfMeta?.alias || [],
    redirect: indexZsD42rxKgfMeta?.redirect || undefined,
    component: () => import("/private/tmp/nix-build-festival-manager-0.0.1.drv-0/incl/pages/archive/index.vue").then(m => m.default || m)
  },
  {
    name: box_45officexldyuuWlZKMeta?.name ?? "box-office",
    path: box_45officexldyuuWlZKMeta?.path ?? "/box-office",
    meta: box_45officexldyuuWlZKMeta || {},
    alias: box_45officexldyuuWlZKMeta?.alias || [],
    redirect: box_45officexldyuuWlZKMeta?.redirect || undefined,
    component: () => import("/private/tmp/nix-build-festival-manager-0.0.1.drv-0/incl/pages/box-office.vue").then(m => m.default || m)
  },
  {
    name: _91hash_93ewu1r6xQdpMeta?.name ?? "film-challenge-guest-registration-hash",
    path: _91hash_93ewu1r6xQdpMeta?.path ?? "/film-challenge/guest-registration/:hash()",
    meta: _91hash_93ewu1r6xQdpMeta || {},
    alias: _91hash_93ewu1r6xQdpMeta?.alias || [],
    redirect: _91hash_93ewu1r6xQdpMeta?.redirect || undefined,
    component: () => import("/private/tmp/nix-build-festival-manager-0.0.1.drv-0/incl/pages/film-challenge/guest-registration/[hash].vue").then(m => m.default || m)
  },
  {
    name: indexscSpdXm0R2Meta?.name ?? "film-challenge",
    path: indexscSpdXm0R2Meta?.path ?? "/film-challenge",
    meta: indexscSpdXm0R2Meta || {},
    alias: indexscSpdXm0R2Meta?.alias || [],
    redirect: indexscSpdXm0R2Meta?.redirect || undefined,
    component: () => import("/private/tmp/nix-build-festival-manager-0.0.1.drv-0/incl/pages/film-challenge/index.vue").then(m => m.default || m)
  },
  {
    name: inspirationCXgTr5FbUpMeta?.name ?? "film-challenge-inspiration",
    path: inspirationCXgTr5FbUpMeta?.path ?? "/film-challenge/inspiration",
    meta: inspirationCXgTr5FbUpMeta || {},
    alias: inspirationCXgTr5FbUpMeta?.alias || [],
    redirect: inspirationCXgTr5FbUpMeta?.redirect || undefined,
    component: () => import("/private/tmp/nix-build-festival-manager-0.0.1.drv-0/incl/pages/film-challenge/inspiration.vue").then(m => m.default || m)
  },
  {
    name: indexmg8Ex1PPSMMeta?.name ?? "film-challenge-studios-studioId",
    path: indexmg8Ex1PPSMMeta?.path ?? "/film-challenge/studios/:studioId()",
    meta: indexmg8Ex1PPSMMeta || {},
    alias: indexmg8Ex1PPSMMeta?.alias || [],
    redirect: indexmg8Ex1PPSMMeta?.redirect || undefined,
    component: () => import("/private/tmp/nix-build-festival-manager-0.0.1.drv-0/incl/pages/film-challenge/studios/[studioId]/index.vue").then(m => m.default || m)
  },
  {
    name: _91teamId_936C3pGwS4r0Meta?.name ?? "film-challenge-studios-studioId-teams-teamId",
    path: _91teamId_936C3pGwS4r0Meta?.path ?? "/film-challenge/studios/:studioId()/teams/:teamId()",
    meta: _91teamId_936C3pGwS4r0Meta || {},
    alias: _91teamId_936C3pGwS4r0Meta?.alias || [],
    redirect: _91teamId_936C3pGwS4r0Meta?.redirect || undefined,
    component: () => import("/private/tmp/nix-build-festival-manager-0.0.1.drv-0/incl/pages/film-challenge/studios/[studioId]/teams/[teamId].vue").then(m => m.default || m)
  },
  {
    name: createTOlBw4lrMeMeta?.name ?? "film-challenge-studios-studioId-teams-create",
    path: createTOlBw4lrMeMeta?.path ?? "/film-challenge/studios/:studioId()/teams/create",
    meta: createTOlBw4lrMeMeta || {},
    alias: createTOlBw4lrMeMeta?.alias || [],
    redirect: createTOlBw4lrMeMeta?.redirect || undefined,
    component: () => import("/private/tmp/nix-build-festival-manager-0.0.1.drv-0/incl/pages/film-challenge/studios/[studioId]/teams/create.vue").then(m => m.default || m)
  },
  {
    name: the_45matchupSBiUZYjQoYMeta?.name ?? "film-challenge-the-matchup",
    path: the_45matchupSBiUZYjQoYMeta?.path ?? "/film-challenge/the-matchup",
    meta: the_45matchupSBiUZYjQoYMeta || {},
    alias: the_45matchupSBiUZYjQoYMeta?.alias || [],
    redirect: the_45matchupSBiUZYjQoYMeta?.redirect || undefined,
    component: () => import("/private/tmp/nix-build-festival-manager-0.0.1.drv-0/incl/pages/film-challenge/the-matchup.vue").then(m => m.default || m)
  },
  {
    name: _91filmGroupId_93qoZRoTBQM8Meta?.name ?? "fivefest-academy-film-groups-filmGroupId",
    path: _91filmGroupId_93qoZRoTBQM8Meta?.path ?? "/fivefest/academy/film-groups/:filmGroupId()",
    meta: _91filmGroupId_93qoZRoTBQM8Meta || {},
    alias: _91filmGroupId_93qoZRoTBQM8Meta?.alias || [],
    redirect: _91filmGroupId_93qoZRoTBQM8Meta?.redirect || undefined,
    component: () => import("/private/tmp/nix-build-festival-manager-0.0.1.drv-0/incl/pages/fivefest/academy/film-groups/[filmGroupId].vue").then(m => m.default || m)
  },
  {
    name: indexUs8ulZPLMGMeta?.name ?? "fivefest-academy",
    path: indexUs8ulZPLMGMeta?.path ?? "/fivefest/academy",
    meta: indexUs8ulZPLMGMeta || {},
    alias: indexUs8ulZPLMGMeta?.alias || [],
    redirect: indexUs8ulZPLMGMeta?.redirect || undefined,
    component: () => import("/private/tmp/nix-build-festival-manager-0.0.1.drv-0/incl/pages/fivefest/academy/index.vue").then(m => m.default || m)
  },
  {
    name: _91submissionId_93U0WrNZ2YkmMeta?.name ?? "fivefest-films-submissionId",
    path: _91submissionId_93U0WrNZ2YkmMeta?.path ?? "/fivefest/films/:submissionId()",
    meta: _91submissionId_93U0WrNZ2YkmMeta || {},
    alias: _91submissionId_93U0WrNZ2YkmMeta?.alias || [],
    redirect: _91submissionId_93U0WrNZ2YkmMeta?.redirect || undefined,
    component: () => import("/private/tmp/nix-build-festival-manager-0.0.1.drv-0/incl/pages/fivefest/films/[submissionId].vue").then(m => m.default || m)
  },
  {
    name: createD4MzSQo8snMeta?.name ?? "fivefest-films-create",
    path: createD4MzSQo8snMeta?.path ?? "/fivefest/films/create",
    meta: createD4MzSQo8snMeta || {},
    alias: createD4MzSQo8snMeta?.alias || [],
    redirect: createD4MzSQo8snMeta?.redirect || undefined,
    component: () => import("/private/tmp/nix-build-festival-manager-0.0.1.drv-0/incl/pages/fivefest/films/create.vue").then(m => m.default || m)
  },
  {
    name: indexfmiVWxUqwhMeta?.name ?? "index",
    path: indexfmiVWxUqwhMeta?.path ?? "/",
    meta: indexfmiVWxUqwhMeta || {},
    alias: indexfmiVWxUqwhMeta?.alias || [],
    redirect: indexfmiVWxUqwhMeta?.redirect || undefined,
    component: () => import("/private/tmp/nix-build-festival-manager-0.0.1.drv-0/incl/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91filmGroupId_93YF2IYnzQJmMeta?.name ?? "judge-filmGroupId",
    path: _91filmGroupId_93YF2IYnzQJmMeta?.path ?? "/judge/:filmGroupId()",
    meta: _91filmGroupId_93YF2IYnzQJmMeta || {},
    alias: _91filmGroupId_93YF2IYnzQJmMeta?.alias || [],
    redirect: _91filmGroupId_93YF2IYnzQJmMeta?.redirect || undefined,
    component: () => import("/private/tmp/nix-build-festival-manager-0.0.1.drv-0/incl/pages/judge/[filmGroupId].vue").then(m => m.default || m)
  },
  {
    name: index870o2QIV0JMeta?.name ?? "judge",
    path: index870o2QIV0JMeta?.path ?? "/judge",
    meta: index870o2QIV0JMeta || {},
    alias: index870o2QIV0JMeta?.alias || [],
    redirect: index870o2QIV0JMeta?.redirect || undefined,
    component: () => import("/private/tmp/nix-build-festival-manager-0.0.1.drv-0/incl/pages/judge/index.vue").then(m => m.default || m)
  },
  {
    name: liveB16r7dHWtxMeta?.name ?? "live",
    path: liveB16r7dHWtxMeta?.path ?? "/live",
    meta: liveB16r7dHWtxMeta || {},
    alias: liveB16r7dHWtxMeta?.alias || [],
    redirect: liveB16r7dHWtxMeta?.redirect || undefined,
    component: () => import("/private/tmp/nix-build-festival-manager-0.0.1.drv-0/incl/pages/live.vue").then(m => m.default || m)
  },
  {
    name: _91filmGroupId_93RAioPA7QjIMeta?.name ?? "online-screening-filmGroupId",
    path: _91filmGroupId_93RAioPA7QjIMeta?.path ?? "/online-screening/:filmGroupId()",
    meta: _91filmGroupId_93RAioPA7QjIMeta || {},
    alias: _91filmGroupId_93RAioPA7QjIMeta?.alias || [],
    redirect: _91filmGroupId_93RAioPA7QjIMeta?.redirect || undefined,
    component: () => import("/private/tmp/nix-build-festival-manager-0.0.1.drv-0/incl/pages/online-screening/[filmGroupId].vue").then(m => m.default || m)
  },
  {
    name: _91filmGroupId_93W04x8mACsxMeta?.name ?? "online-screening-five-fest-filmGroupId",
    path: _91filmGroupId_93W04x8mACsxMeta?.path ?? "/online-screening/five-fest/:filmGroupId()",
    meta: _91filmGroupId_93W04x8mACsxMeta || {},
    alias: _91filmGroupId_93W04x8mACsxMeta?.alias || [],
    redirect: _91filmGroupId_93W04x8mACsxMeta?.redirect || undefined,
    component: () => import("/private/tmp/nix-build-festival-manager-0.0.1.drv-0/incl/pages/online-screening/five-fest/[filmGroupId].vue").then(m => m.default || m)
  },
  {
    name: indexhVAbNA0GnzMeta?.name ?? "online-screening-five-fest",
    path: indexhVAbNA0GnzMeta?.path ?? "/online-screening/five-fest",
    meta: indexhVAbNA0GnzMeta || {},
    alias: indexhVAbNA0GnzMeta?.alias || [],
    redirect: indexhVAbNA0GnzMeta?.redirect || undefined,
    component: () => import("/private/tmp/nix-build-festival-manager-0.0.1.drv-0/incl/pages/online-screening/five-fest/index.vue").then(m => m.default || m)
  },
  {
    name: indexK3wXll68AlMeta?.name ?? "online-screening",
    path: indexK3wXll68AlMeta?.path ?? "/online-screening",
    meta: indexK3wXll68AlMeta || {},
    alias: indexK3wXll68AlMeta?.alias || [],
    redirect: indexK3wXll68AlMeta?.redirect || undefined,
    component: () => import("/private/tmp/nix-build-festival-manager-0.0.1.drv-0/incl/pages/online-screening/index.vue").then(m => m.default || m)
  },
  {
    name: oryY0vczVukYeMeta?.name ?? "ory",
    path: oryY0vczVukYeMeta?.path ?? "/ory",
    meta: oryY0vczVukYeMeta || {},
    alias: oryY0vczVukYeMeta?.alias || [],
    redirect: oryY0vczVukYeMeta?.redirect || undefined,
    component: () => import("/private/tmp/nix-build-festival-manager-0.0.1.drv-0/incl/pages/ory.vue").then(m => m.default || m)
  },
  {
    name: indexrY4rV2pJv6Meta?.name ?? "prize-sponsor",
    path: indexrY4rV2pJv6Meta?.path ?? "/prize-sponsor",
    meta: indexrY4rV2pJv6Meta || {},
    alias: indexrY4rV2pJv6Meta?.alias || [],
    redirect: indexrY4rV2pJv6Meta?.redirect || undefined,
    component: () => import("/private/tmp/nix-build-festival-manager-0.0.1.drv-0/incl/pages/prize-sponsor/index.vue").then(m => m.default || m)
  },
  {
    name: save_45the_45datesBRHsfRpdIOMeta?.name ?? "save-the-dates",
    path: save_45the_45datesBRHsfRpdIOMeta?.path ?? "/save-the-dates",
    meta: save_45the_45datesBRHsfRpdIOMeta || {},
    alias: save_45the_45datesBRHsfRpdIOMeta?.alias || [],
    redirect: save_45the_45datesBRHsfRpdIOMeta?.redirect || undefined,
    component: () => import("/private/tmp/nix-build-festival-manager-0.0.1.drv-0/incl/pages/save-the-dates.vue").then(m => m.default || m)
  },
  {
    name: indexP5bHRYT5Q6Meta?.name ?? "selection-filmGroupId",
    path: indexP5bHRYT5Q6Meta?.path ?? "/selection/:filmGroupId()",
    meta: indexP5bHRYT5Q6Meta || {},
    alias: indexP5bHRYT5Q6Meta?.alias || [],
    redirect: indexP5bHRYT5Q6Meta?.redirect || undefined,
    component: () => import("/private/tmp/nix-build-festival-manager-0.0.1.drv-0/incl/pages/selection/[filmGroupId]/index.vue").then(m => m.default || m)
  },
  {
    name: reviewjfDdIamJbyMeta?.name ?? "selection-filmGroupId-review",
    path: reviewjfDdIamJbyMeta?.path ?? "/selection/:filmGroupId()/review",
    meta: reviewjfDdIamJbyMeta || {},
    alias: reviewjfDdIamJbyMeta?.alias || [],
    redirect: reviewjfDdIamJbyMeta?.redirect || undefined,
    component: () => import("/private/tmp/nix-build-festival-manager-0.0.1.drv-0/incl/pages/selection/[filmGroupId]/review.vue").then(m => m.default || m)
  },
  {
    name: indexGrpwBmZr7rMeta?.name ?? "selection",
    path: indexGrpwBmZr7rMeta?.path ?? "/selection",
    meta: indexGrpwBmZr7rMeta || {},
    alias: indexGrpwBmZr7rMeta?.alias || [],
    redirect: indexGrpwBmZr7rMeta?.redirect || undefined,
    component: () => import("/private/tmp/nix-build-festival-manager-0.0.1.drv-0/incl/pages/selection/index.vue").then(m => m.default || m)
  }
]