import {VAutocomplete} from 'vuetify/components/VAutocomplete'
import {VCheckbox} from 'vuetify/components/VCheckbox'
import {VDivider} from 'vuetify/components/VDivider'
import {VSelect} from 'vuetify/components/VSelect'
import {VTextarea} from 'vuetify/components/VTextarea'
import {VTextField} from 'vuetify/components/VTextField'
import {VSlider} from 'vuetify/components/VSlider'

export const isDev = false
export function vuetifyConfiguration() {
  const options = JSON.parse('{"theme":{"defaultTheme":"dark","themes":{"dark":{"colors":{"primary":"#BB86FC","primary-darken-1":"#3700B3","secondary":"#03DAC5","secondary-darken-1":"#03DAC5"}}}}}')
  
  
  options.components = {VAutocomplete,VCheckbox,VDivider,VSelect,VTextarea,VTextField,VSlider}
  
  return options
}

