import { ProviderEvents } from "@openfeature/web-sdk"

export default defineNuxtPlugin(() => {
  const { $of } = useNuxtApp()

  $of.addHandler(ProviderEvents.ConfigurationChanged, (event) => {
    if (event?.flagsChanged?.includes('maintenance_mode')) {
      if (!$of.getBooleanValue('maintenance_mode', false)) return

      return navigateTo('/', { replace: true })
    }
  })


  addRouteMiddleware((to) => {
    if (to.path == '/') {
      return
    }

    const openFeature = useOpenFeature()
    if (!openFeature.getBool('maintenance_mode', false)) return

    return navigateTo('/', { replace: true })
  })
})
